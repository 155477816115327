<template>
  <!-- Feature Section Begin -->

  <section class="feature spad">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div class="feature__text">
            <div class="section-title">

              <h2>A Nossa História</h2>
            </div>
            <div class="feature__text__desc">
              <p style="text-align:justify">O AC MicroBanco Lda, Fundada em 5 de Maio 2012, autorizada e supervisionada pelo Banco de Moçambique é especializada na prestação de serviços de crédito.
                Emergiu da vontade e conhecimentos de um grupo de profissionais com vasta experiência nas mais variadas áreas de trabalho.</p>
            </div>
            <div class="feature__text__btn">
              <router-link :to="{name: 'About'}" class="primary-btn">Saiba mais</router-link>
            </div>
            <br>
            <div class="section-title">
              <!--<span>Visão e Missão</span>-->
              <h2>Visão e Missão</h2>
            </div>
            <div class="feature__text__desc">
              <p style="text-align:justify">Prestar serviços financeiros as populações de baixa e média renda, com agilidade e competência, visando a criação de valor aos sócios e a sociedade.</p>
            </div>
            <div class="feature__text__btn">
              <router-link :to="{name: 'About'}" class="primary-btn">Saiba mais</router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 offset-lg-4">
          <div class="row">
            <div class="col-lg-6 col-md-4 col-6">
              <div class="feature__item">
                <!--<img src="img/feature/feature-1.png" alt="Cerdito Pessoal Rapido" class="img-thumbnail" width="200" height="200">-->

                <div class="feature__item__icon">
                  <router-link :to="{name: 'CreditoPessoalRapido'}" ><img src="img/feature/feature-1.png" alt=""></router-link>
                </div>
                <div class="feature__text__btn">
                  Crédito Pessoal Rápido
                </div>
              </div>

            </div>
            <div class="col-lg-6 col-md-4 col-6">
              <div class="feature__item">
                <div class="feature__item__icon">
                  <router-link :to="{name: 'CreditoAutomovel'}" ><img src="img/feature/feature-2.png" alt=""></router-link>
                </div>
                Crédito Automóvel
              </div>
            </div>
            <div class="col-lg-6 col-md-4 col-6">
              <div class="feature__item">
                <div class="feature__item__icon">
                  <router-link :to="{name: 'CreditoAutomovel'}" ><img src="img/feature/feature-3.png" alt=""></router-link>
                </div>
                <div class="feature__text__btn">
                  Crédito Lar Recheio
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-4 col-6">
              <div class="feature__item">
                <div class="feature__item__icon">
                  <router-link :to="{name: 'CreditoHabitacao'}" ><img src="img/feature/feature-4.png" alt=""></router-link>
                </div>
                <div class="feature__text__btn">
                  Crédito Habitação<br/>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-4 col-6">
              <div class="feature__item">
                <div class="feature__item__icon">
        
                  <router-link :to="{name: 'CreditoHabitacao'}" ><img src="img/feature/feature-5.png" alt=""></router-link>
                </div>
                <div class="feature__text__btn">
                  Crédito Rural Agropecuária
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-4 col-6">
              <div class="feature__item">
                <div class="feature__item__icon">
                  <router-link :to="{name: 'CreditoPME'}"><img src="img/feature/feature-6.png" alt=""></router-link>
                </div>
                <div class="feature__text__btn">
                  Crédito PME - Comércio
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Feature Section End -->
</template>

<script>
export default {
  name: "m-features"
}
</script>

<style scoped>

</style>