<template>
  <!-- Chooseus Section Begin -->
  <section class="chooseus spad">
    <div class="container">
      <div class="row">
        <!--<div class="col-lg-5">
            <div class="chooseus__text">
                <div class="section-title">
                    <h2>Missão, Visão e Valores</h2>

                </div>
                <ul>
                    <li><i class="fa fa-check-circle"></i>
                    <b>Visão</b> - Prestar serviços financeiros as populações de baixa e média renda, com agilidade e competência, visando a criação de valor aos sócios e a sociedade
                    </li>
                    <li><i class="fa fa-check-circle"></i>
                    <b>Missão</b>
                        Ser reconhecido como a melhor e mais eficiente Instituiçao de Microfinanças e como ferramenta de geraçao de emprego e renda sustentavel.
                    </li>
                    <li><i class="fa fa-check-circle"></i>
                        <b>Valores</b><br>
                        <b>Transparência:</b> Ser transparente nas relações internas e externas;<br>
                      <b>Prontidão:</b> Vitalidade e aprendizagem contínua;<br>
                      <b>Proximidade:</b> Uma equipa de profissionais disponíveis para o atender;<br>
                      <b>Modernidade:</b> Apostamos na inovação de produtos e nas novas tecnologias.

                    </li>
                </ul>
                <a href="#" class="primary-btn">Acerca</a>
            </div>
        </div>-->
      </div>
    </div>
    <div class="chooseus__video set-bg">
      <img src="img/chooseus-video.png" alt="">

    </div>
  </section>
  <!-- Chooseus Section End -->
</template>

<script>
export default {
  name: "m-choseous"
}
</script>

<style scoped>

</style>